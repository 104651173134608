<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="mb-2">
          <h3>
            อันดับสินค้าขายดีสินค้า
          </h3>
        </div>
        <div class="row ">
          <div class="col-12 col-sm-4 col-xl-3">
            <b-form-group
              label="เริ่มต้น"
              label-for="vi-first-name"
            >
              <DatePicker
                ref="updateDate"
                :date="inputs.dateStart"
                :type="'start'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div
            class="col-12 col-sm-4 col-xl-3"
          >
            <b-form-group
              label="
            สิ้นสุด"
              label-for="vi-first-name"
            >
              <DatePicker
                :date="inputs.dateEnd"
                :type="'end'"
                :status="false"
                @setDatePicker="onSetDatePicker"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-sm-4 align-self-center">
            <b-form-checkbox
              v-model="inputs.checkedList"
              checked="true"
              name="check-button"
              switch
              inline
            >
              ทั้งหมด
            </b-form-checkbox>

          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-12 col-sm-4 col-xl-3 ">
            <b-form-group
              label="ค้นหา"
              label-for="vi-first-name"
            >
              <b-form-input
                v-model="searchTerm"
                placeholder="ค้นหา"
                type="text"
                class="d-inline-block mr-1"
              />
            </b-form-group>

          </div>

        </div>
        <b-table
          striped
          hover
          responsive
          class="type-relative"
          :per-page="inputsnode.perPage"
          :current-page="inputsnode.currentPage"
          :items="listOrder"
          :fields="fields"
          :filter="searchTerm"
          show-empty
          @filtered="onFiltered"
        >
          <template #cell(rank)="data">
            {{ (data.item.index+1) }}
          </template>
          <template #cell(code)="data">
            {{ data.item.item.product[0].code }}
          </template>
          <template #cell(name)="data">
            {{ data.item.item.product[0].name }}
          </template>
          <template #cell(amount)="data">
            <p>{{ data.item.item.total.$numberDecimal | toAmount }} ชิ้น</p>
          </template>
          <!-- <template #cell(button)="data">
            <div class="d-grid d-md-block">
              <button
                class="btn btn-primary mr-1"
                type="button"
                @click="openModal(data.item.index)"
              >
                รายละเอียด
              </button>
              <button
                class="btn btn-info mr-1"
                type="button"
              >
                รายงาน
              </button>
            </div>
            </template> -->
          <template #empty>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลสินค้า</h4>
            </div>
          </template>
          <template #emptyfiltered>
            <div class="col-md-12 text-center m-1">
              <h4>ไม่พบข้อมูลสินค้า</h4>
            </div>
          </template>
        </b-table>
        <b-card-body
          v-if="totalItem > 0"
          class="d-flex justify-content-between flex-wrap pt-0 mt-2"
        >

          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="inputsnode.perPage"
              size="sm"
              inline
              :options="inputsnode.pageOptions"
            />
          </b-form-group>

          <div>
            <b-pagination
              v-model="inputsnode.currentPage"
              :total-rows="inputsnode.totalRows"
              :per-page="inputsnode.perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </div>

    </div>

  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BTable, BFormGroup, BFormSelect, BPagination, BModal, BButton, VBModal, BCardBody, BFormInput, BFormCheckbox
  , BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import DatePicker from '@/views/components/production/datePicker.vue'

export default defineComponent({
  name: 'reportproductsell',
  components: {
    BRow,
    BCol,
    BTable,
    BButton,
    BFormSelect,
    BPagination,
    BCardBody,
    BFormGroup,
    BCardCode,
    BModal,
    VBModal,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    required,
    DatePicker,
    BFormCheckbox
  },
  watch: {
    'inputs.checkedList': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateStart': {
      handler (val) {
        this.getListOrder()
      }
    },
    'inputs.dateEnd': {
      handler (val) {
        this.getListOrder()
      }
    }
  },
  data () {
    return {
      IsopenModalReport: false,
      search: '',
      IsopenModal: false,
      indexDetail: 0,
      currentPageDetail: 0,
      inputs: {
        dateStart: moment().format('YYYY-MM-DD'),
        dateEnd: moment().format('YYYY-MM-DD'),
        checkedList: false
      },
      listOrder: [],
      searchTerm: '',
      fields: [
        {
          key: 'rank', label: 'อันดับ', thStyle: 'min-width: 75px', sortable: true
        },
        {
          key: 'code', label: 'รหัสสินค้า', thStyle: 'min-width: 165px', sortable: true
        },
        {
          key: 'name', label: 'ชื่อสินค้า', thStyle: 'min-width: 250px'
        },
        {
          key: 'amount', label: 'จำนวนสินค้า', thStyle: 'min-width:300px', tdClass: 'text-right', thClass: 'text-right'
        }
      ],

      inputsnode: {
        perPage: 5,
        pageOptions: [5, 10, 15],
        totalRows: 0,
        itemsPerPage: 10,
        currentPage: 1,
        startItem: 0,
        endItem: 10
      }
    }
  },
  computed: {
    totalItem () {
      return this.inputsnode.totalRows
    }
  },
  methods: {
    onSetDatePicker (data) {
      if (data.type === 'start') this.inputs.dateStart = data.val; else this.inputs.dateEnd = data.val
    },
    onFiltered (filteredItems) {
      this.inputsnode.totalRows = filteredItems.length
      this.inputsnode.currentPage = 1
    },
    async loader () {
      await this.lander()
      this.getListOrder()
    },
    lander () {
      if (this.$route.params.startdate !== undefined) {
        this.inputs.dateStart = this.$route.params.startdate
        this.inputs.dateEnd = this.$route.params.enddate
      } else {
        this.inputs.checkedList = true
      }
    },
    getListOrder () {
      this.$store.dispatch('report/getReportProduct', { startDate: this.inputs.checkedList ? '' : this.inputs.dateStart, endDate: this.inputs.checkedList ? '' : this.inputs.dateEnd }).then(res => {
        if (res.status === 200) {
          this.listOrder = []
          res.data.items.forEach((data, i) => {
            this.listOrder.push({
              item: data,
              index: i
            })
          })
          this.inputsnode.totalRows = this.listOrder.length
        }
      })
    }

  },
  created () {
    this.loader()
  }
})
</script>
<style>
.b-table-sticky-header {
    max-height: 300px !important;
}

.modal-title {
        color: white !important;
}
</style>
